import React from "react";
import { useCart, useDispatchCart } from "../../contextReducer";
import NoImage from "../../../assets/features/no-image.svg" 

const CartOrder = ({ item, ind }) => {
  const data = useCart();
  const dispatch = useDispatchCart();

  const cartItem = data.find((temp) => {
    return temp.productId === item.productId;
  });

  const qty = cartItem ? cartItem.qty : 1;

  return (
    <>
      <div className="h-[84px] mt-3 flex flex-row" style={{ minWidth: "100%" }}>
        {/* image */}
        <div style={{ minWidth: "70px" }}>
          <img
            className="rounded-2xl w-[83px] h-[84px]"
            alt="Added product"
            src={item.img ? item.img : NoImage}
          />
        </div>

        <div className="flex flex-row justify-between w-[80%] ml-[24px]">
          <div className="flex flex-col items-start mt-[7px]">
            <div className="font-medium">{item.name}</div>
            <div className="flex items-center mt-[14px] text-sienna">
              <div
                className="flex items-center justify-center w-[23.7px] h-[23.7px] rounded-full border border-sienna bg-[#F5EFEF] hover:cursor-pointer"
                onClick={() => {
                  if (qty > 1) {
                    dispatch({
                      type: "UPDATE",
                      productId: item.productId,
                      price: item.price,
                      img: item.img,
                      qty: qty - 1,
                    });
                  }
                }}
              >
                <span className="text-3xl">-</span>
              </div>
              <div className="d-flex justify-center mx-2 w-[12px] text-gray-100">
                {qty}
              </div>
              <button
                className="flex items-center justify-center w-[23.7px] h-[23.7px] rounded-full border border-sienna bg-[#F5EFEF]"
                onClick={() => {
                  dispatch({
                    type: "UPDATE",
                    productId: item.productId,
                    price: item.price,
                    img: item.img,
                    qty: qty + 1,
                  });
                }}
              >
                <span className="text-2xl">+</span>
              </button>

              <div className="ml-[20px] rounded-lg overflow-hidden flex items-center justify-center py-[5px] px-2.5 text-3xs text-sienna bg-[#F5EFEF]">
                <button
                  className="font-medium"
                  onClick={() => {
                    dispatch({ type: "REMOVE", index: ind });
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
          <div className="font-medium">{`$${(item.price * item.qty).toFixed(
            2
          )}`}</div>
        </div>
      </div>
      <div className="mt-3 ms-[10px] box-border h-px border-t-[1px] border-solid border-lightgray" />
    </>
  );
};

export default CartOrder;
