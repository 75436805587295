import { Routes, Route } from "react-router-dom";
import LoginPage from "../src/pages/loginPage";
import HomePage from "./pages/homePage";
import { ChakraProvider } from "@chakra-ui/react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import PortraitWarning from "./components/PortraitWarning";
import { CardProvider } from "./components/contextReducer";
import PrivateRoute from "./components/privateRoute";
import NotPageFound404 from "./pages/notPageFound404";

function App() {
  const [isPortrait, setIsPortrait] = useState(
    window.innerHeight > window.innerWidth
  );

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ChakraProvider>
      <CardProvider>
        <div>
          {/* {isPortrait ? (
            <PortraitWarning />
          ) : ( */}
            <div>
              <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="" element={<PrivateRoute />}>
                  <Route path="/home" element={<HomePage />} />
                </Route>

                <Route path="*" element={<NotPageFound404 />} />
              </Routes>
            </div>
          {/* )} */}
        </div>
      </CardProvider>
    </ChakraProvider>
  );
}

export default App;
