import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useCart } from "./contextReducer";

function UploadOfflineModal(props) {
  const navigate = useNavigate();
  const data = useCart();

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        dialogClassName="w-25 min-w-[330px]"
        centered
      >
        <div
          className="d-flex flex-column justify-center items-center h-[190px]"
          style={{ borderRadius: "15px" }}
        >
          <div
            className="w-[260px] text-center"
            style={{
              color: "rgba(99, 99, 99, 1)",
              fontWeight: "500",
              fontSize: "19px",
              lineHeight: "28.5px",
            }}
          >
            You have successfully Uploaded{" "}
            <span style={{color:"#E06F2C"}}> {data.length}</span> Offline
            orders
          </div>
          <buton className="d-flex justify-center items-center w-[75px] h-[32px] bg-chocolate-500 text-white mt-[30px]" style={{borderRadius:'5px'}}>Done</buton>
        </div>
      </Modal>
    </>
  );
}

export default UploadOfflineModal;
