import React, { useEffect, useState, useContext } from "react";
import Header from "./header";
import CategoryProduct from "../categoryProduct";
import GridViewProduct from "../gridViewProduct";
import RightLayout from "./rightLayout/rightLayout";
import axios from "axios";
import { AuthContext } from "../../auth/authProvider";

const Layout = ({ setShowDropdown, showDropdown }) => {
  const { user, token } = useContext(AuthContext);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const fetchCategoryData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/category/getAll`,
        { userId: user.id },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const sortedCategories = response.data.data.sort((a, b) => a.pos - b.pos);
      setCategories(sortedCategories);
    } catch (error) {
      console.error("Failed to fetch categories");
    }
  };

  useEffect(() => {
    fetchCategoryData();
  }, []);

  return (
    <div
      className="d-flex flex-row"
      style={{
        backgroundColor: "#F8F8F8",
      }}
    >
      <div
        className="d-flex flex-col pt-[2%] md:w-[50%] lg:w-[60%] xl:w-[70%] 2xl:w-[75%] pl-[3%]"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <div className="d-flex align-items-center" style={{ height: "10%" }}>
          <Header
            setShowDropdown={setShowDropdown}
            showDropdown={showDropdown}
            user={user}
          />
        </div>
        <div
          className="hide-scrollbar d-flex overflow-x-auto mask items-center h-[25%] md:h-auto lg:h-[20%]"
          style={{
            alignItems: "center",
          }}
        >
          <CategoryProduct
            categories={categories}
            setSelectedCategoryId={setSelectedCategoryId}
          />
        </div>

        <div
          className="custom-scrollbar overflow-y-auto pr-[3%] h-65% md:h-[70%] mt-[2%] xl:mt-2"
          style={{
            height: "70%",
            overflowX: "hidden",
          }}
        >
          <GridViewProduct
            categories={categories}
            selectedCategoryId={selectedCategoryId}
          />
        </div>
      </div>

      <div
        className="d-flex md:w-[50%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%]"
        style={{ height: "100vh" }}
      >
        <RightLayout />
      </div>
    </div>
  );
};

export default Layout;
