import React, { useContext, useEffect, useState } from "react";
import CartOrder from "./CartOrder";
import emptyCart from "../../../assets/features/shopping-cart 1.svg";
import AccountType from "./AccoutType";
import { useCart, useDispatchCart } from "../../contextReducer";
import axios from "axios";
import { AuthContext } from "../../../auth/authProvider";
import toast from "react-hot-toast";

const RightLayout = () => {
  const data = useCart();
  const dispatch = useDispatchCart();
  const { user, token, setUser } = useContext(AuthContext);
  const [accountType, setaccountType] = useState("Default");
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  function generateRandomStringWithCash() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "CASH";
    for (let i = 0; i < 10; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const clearCart = () => {
    dispatch({ type: "CLEAR_CART" });
  };

  let totalPrice = data
    .reduce((total, item) => total + item.price * item.qty, 0)
    .toFixed(2);

  let profit = data
    .reduce((total, item) => {
      return total + (item.price * item.qty - item.cost * item.qty);
    }, 0)
    .toFixed(2);

  let costTotal = data
    .reduce((total, item) => total + item.cost * item.qty, 0)
    .toFixed(2);

  let totalItems = data.reduce((total, item) => {
    return total + item.qty;
  }, 0);

  const [orderedData, setOrderedData] = useState({
    userId: user.id,
    accountId: "",
    orderedItems: [],
    method: "",
    profit: 0.0,
    total: 0.0,
  });

  const refreshUser = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/updatedUserData`,
        { userId: user.id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.success) setUser({ ...user, ...res.data.data });
    } catch (error) {
      console.error("Failed to refresh user", error);
    }
  };

  const fetchAllAccounts = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/account/getAllAccounts`,
        { userId: user.id },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await refreshUser();
      setAccounts(response.data.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchAllAccounts();
  }, [user.id, token]);

  const handleCreateOrder = async (e) => {
    try {
      const payMethod = e.target.id;
      setIsLoading(true);
      toast.loading("placing order...");
      const payload = {
        ...orderedData,
        orderedItems: data,
        method: payMethod,
        total: parseFloat(totalPrice),
        profit: parseFloat(profit),
      };

      if (
        payMethod === "Cash" &&
        user?.isCashCheckoutEnabled &&
        !selectedAccount
      ) {
        payload.accountId = generateRandomStringWithCash();
      } else if (accountType === "Cost") {
        payload.total = parseFloat(costTotal);
        payload.profit = 0.0;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/order/create`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.dismiss();
      toast.success("Order Placed Successfully");
      setOrderPlaced(true);
      clearCart();
      setaccountType("Default");
      setTimeout(() => {
        setOrderedData({
          userId: user.id,
          accountId: "",
          orderedItems: [],
          method: "",
          profit: 0.0,
          total: 0.0,
        });
        setOrderPlaced(false);
      }, 3000);
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const isCashEnabled =
    (user?.isCashCheckoutEnabled && data.length > 0 && !isLoading) ||
    (accountType !== "Default" && data.length > 0 && !isLoading);

  const isAccountEnabled =
    accountType !== "Default" && data.length > 0 && !isLoading;

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <div className="hidden sm:flex flex-column bg-white">
        <div>
          <AccountType
            accountType={accountType}
            setaccountType={setaccountType}
            accounts={accounts}
            setOrderedData={setOrderedData}
            orderedData={orderedData}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
        </div>

        {/* OrderDetails */}
        <div
          className="d-flex flex-column sm:h-[57vh] px-[30px]"
          style={{ minHeight: "400px" }}
        >
          <div className="d-flex flex-column h-100 w-[100]">
            {data.length !== 0 ? (
              <>
                <div
                  className="d-flex h-[36px] fw-bold mt-4"
                  style={{
                    fontSize: "24px",
                    lineHeight: "36px",
                  }}
                >
                  Order
                </div>

                <div className="h-100 custom-scrollbar overflow-y-auto overflow-x-hidden">
                  <div className="mr-[14px]">
                    {data.map((item, index) => {
                      return (
                        <CartOrder
                          key={index}
                          item={item}
                          ind={index}
                          orderedData={orderedData}
                          setOrderedData={setOrderedData}
                        />
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <div className="d-flex flex-column justify-center items-center h-[100vh]">
                <img
                  src={emptyCart}
                  alt="empty cart"
                  className="w-[146px] h-[146px] "
                />
                <div className="f-flex flex-col text-center text-[18px] font-poppins text-[#E06F2C] mt-[26px]">
                  Your cart is empty,
                  <br />
                  add a product by taping on one.
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Footer */}
        <div className="h-[25vh] d-flex flex-col align-items-center justify-content-center px-[40px]">
          {/* Dashed Line */}
          <div className="w-full box-border h-px border-t-[3px] border-dashed border-gray-200" />

          {/* Total cost */}
          <div className="flex flex-row justify-between w-full h-[29px] text-[18px] text-gray-100 font-poppins mt-[23px]">
            <div className="font-medium">Total</div>
            <div className="d-flex font-medium">
              <div>{`(${totalItems})`}</div>
              <div className="w-[80px] text-right">
                {" "}
                &#36; {`${totalPrice}`}
              </div>
            </div>
          </div>

          {/* Cash and account */}
          {orderPlaced ? (
            <div className="w-full mt-[29px]">
              <button
                className="rounded-[70px] w-full h-[60px]"
                style={{
                  boxShadow: "0px 3px 21px 0px #00000040",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "27px",
                }}
              >
                Order Successful!
              </button>
            </div>
          ) : (
            <div className="flex flex-row justify-between w-full mt-[29px] space-x-5">
              <button
                className={`rounded-11xl w-[141px] h-[60px] fw-600 text-white font-poppins ${
                  isCashEnabled ? "" : "cursor-not-allowed"
                }`}
                style={{
                  backgroundColor: `${
                    isCashEnabled ? "black" : "rgba(0, 0, 0, 0.36)"
                  }`,
                }}
                onClick={handleCreateOrder}
                id="Cash"
                disabled={!isCashEnabled}
              >
                Cash
              </button>

              <button
                className={`rounded-11xl w-[210px] h-[60px] font-poppins fw-600 text-white ${
                  isAccountEnabled ? "" : "cursor-not-allowed"
                }`}
                style={{
                  background: `${
                    !isAccountEnabled
                      ? "rgba(224, 111, 44, 0.43)"
                      : "linear-gradient(99.78deg, #E06F2C 24.73%, #FFB570 109.55%)"
                  }`,
                  boxShadow: "0px 3px 21px 0px rgba(0, 0, 0, 0.16)",
                }}
                onClick={handleCreateOrder}
                id="Account"
                disabled={!isAccountEnabled}
              >
                Account
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RightLayout;
