import React from "react";

function CommonButton({background, name, onClick}) {
  return (
    <button
      className="px-8 py-[10px] rounded-xl text-white shadow"
      style={{
        background:
          background || "linear-gradient(99.78deg, #E06F2C 24.73%, #FFB570 109.55%)",
      }}
      onClick={onClick}
    >
      {name}
    </button>
  );
}

export default CommonButton;
