import React, { useState } from "react";
import { useDispatchCart, useCart } from "./contextReducer";
import Modal from "./common/Modal";
import AddOnModalContent from "./addOnModalContent";

const FilteredProduct = ({ item }) => {
  const data = useCart();
  const [addOnModalVisible, setAddonModalVisible] = useState(false);
  const dispatch = useDispatchCart();
  
  const cartItem = data.find((temp) => temp.productId === item.productId);
  const qty = cartItem ? cartItem.qty : 1;

  const handleModalClose = async (shouldAddToCart, selectedChips =[]) => {
    setAddonModalVisible(false);
    
    if (shouldAddToCart) {
      const addOnPrice = selectedChips.length > 0 
      ? item.addOns
          .filter((_, index) => selectedChips.includes(index))
          .reduce((sum, addOn) => sum + addOn.price, 0)
      : 0;
    
    const totalPrice = item.price + parseFloat(addOnPrice);
    console.log("addOnPrice: ", addOnPrice);
    console.log("item.price:", item.price);
      let existingCartItem = data.find(prod => prod.productId === item.productId);

      if (existingCartItem) {
        await dispatch({
          type: "UPDATE",
          productId: item.productId,
          price: totalPrice,
          img: item.productImgUrl,
          qty: qty + 1,
        });
      } else {
        await dispatch({
          type: "ADD",
          productId: item.productId,
          name: item.productName,
          price: totalPrice,
          img: item.productImgUrl,
          qty: qty,
          cost: item.cost,
        });
      }
    }
  };

  const handleItemClick = () => {
    if(item.addOns)
      setAddonModalVisible(true);
    else 
        handleModalClose(true);
  };

  return (
    <>
      <div className="sm:w-auto h-auto sm:h-[149px] bg-white rounded-lg overflow-hidden">
        <div
          className="flex flex-row py-[13px] px-[14px] gap-[20px] transition duration-300 ease-in-out hover:bg-gray-500 hover:rounded-lg h-[100%]"
          onClick={handleItemClick}
        >
          {item.productImgUrl && (
            <div
              className="flex-shrink-0 rounded-[16px]"
              style={{ background: "#F8F8F8" }}
            >
              <img
                className="rounded-2xl w-[109px] h-[124px] sm:mb-0"
                alt="product-img"
                src={item.productImgUrl}
              />
            </div>
          )}
          <div className="">
            <div className="flex flex-col ml-[19px] justify-between h-[124px] py-[5px]">
              <div className="font-medium text-lg sm:text-xl max-w-[150px] product-name">
                {item.productName}
              </div>
              <b className="text-xl sm:text-2xl">{`$${item.price}`}</b>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isVisible={addOnModalVisible}
        onClose={handleModalClose}
      >
        <AddOnModalContent onClose={handleModalClose} item={item}/>
      </Modal>
    </>
  );
};

export default FilteredProduct;