import React, { useCallback, useEffect, useState } from "react";

const SelectableGrid = ({ cols, items, children }) => {
  const [selectedBox, setSelectedBox] = useState(null);
  const [rows, setRows] = useState(1);

  useEffect(() => {
    const itemCount = items.length;
    const calculateRows = Math.ceil(itemCount / cols);
    setRows(calculateRows);
  }, [cols, items.length]);

  const toggleSelected = useCallback((index) => {
    setSelectedBox((prevSelected) => (prevSelected === index ? null : index));
  }, []);

  return (
    <div
      className="grid-container"
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        rowGap: "20px",
        columnGap: "20px",
      }}
    >
      {[...Array(rows * cols).keys()].map((index) => {
        if (index < items.length) {
          return (
            <div key={index}>
              {children({
                onClick: () => toggleSelected(index),
                isSelected: selectedBox === index,
                item: items[index],
              })}
            </div>
          );
        } else {
          return <div key={index} className="empty-grid-item" />;
        }
      })}
    </div>
  );
};

export default SelectableGrid;
