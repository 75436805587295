import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiKey2Fill } from "react-icons/ri";
import ipad from "../assets/iPad.webp";
import lock from "../assets/features/lock.svg";
import background from "../assets/background.webp";
import { AuthContext } from "../auth/authProvider";
import toast from "react-hot-toast";
import { Spinner } from "@chakra-ui/react";
import logo from "../assets/CamposLogo.png";
import PoweredBy from "../components/common/PoweredBy";
import Button from "../components/common/Button";

export default function LoginPage() {
  const navigate = useNavigate();
  const { loginUser, loading, token } = useContext(AuthContext);
  const [loginData, setLoginData] = useState({
    loginKey: "",
    pin: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (token) navigate("/home");
  });

  // if (loading) {
  //   return (
  //     <div className="flex items-center justify-center h-screen">
  //       <Spinner />
  //     </div>
  //   );
  // }

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!loginData.loginKey || !loginData.pin) {
      setError("login key or PIN is required. Please try again.");
      return;
    }

    try {
      toast.loading("Please wait...");
      await loginUser(loginData.loginKey, loginData.pin);
      toast.dismiss();
      toast.success("login successful!");
      setTimeout(() => {
        navigate("/home");
      }, 1);
    } catch (error) {
      toast.dismiss();
      if (error.response && error.response.status === 401) {
        setError("login PIN is incorrect. Please try again.");
      } else if (error.response && error.response.status === 404) {
        setError("login key is incorrect. Please try again.");
      } else {
        setError(error?.response?.data?.message ||  "An error occurred");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  };

  return (
    <div className="d-flex flex-row overflow-hidden">
      <div
        className="d-flex flex-col align-items-center justify-content-center w-[100vw] sm:w-[55vw]"
        style={{ height: "100vh", background: "#FFFFFF" }}
      >
        <div className="flex flex-grow items-center">
          <div
            className="d-flex flex-column justify-content-around items-center"
            style={{ maxWidth: "377px" }}
          >
            <img
              src={logo}
              alt="camp pos logo"
              className="w-[156px] h-[158px]"
            />
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: "30px",
                fontWeight: "700",
                lineHeight: "45px",
                textAlign: "left",
              }}
            >
              KIOSK LOGIN
            </div>
            <div
              className="d-flex justify-center h-[24px] mb-[27px] mt-[18px]"
              style={{ fontWeight: "400", color: "#525252" }}
            >
              Login with your information below
            </div>
            <div
              className="d-flex flex-row align-items-center mb-3 pr-[12px]"
              style={{
                gap: "2px",
                width: "100%",
                height: "52px",
                borderRadius: "16px",
                background: "#E06F2C0F",
              }}
            >
              <span
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: "24px",
                  height: "24px",
                  marginLeft: "10px",
                }}
              >
                <RiKey2Fill width="16px" height="21px" alt="user icon" />
              </span>
              <span className="d-flex justify-content-center align-items-center">
                <input
                  autoFocus
                  type="text"
                  placeholder="Login Key"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  name="loginKey"
                  value={loginData.loginKey}
                  id="key"
                  size="18"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    padding: "5px",
                    outline: "none",
                    width: "100%",
                  }}
                />
              </span>
            </div>
            <div
              className="d-flex flex-row align-items-center mb-3"
              style={{
                gap: "2px",
                width: "100%",
                height: "52px",
                borderRadius: "16px",
                background: "#E06F2C0F",
              }}
            >
              <span
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: "24px",
                  height: "24px",
                  marginLeft: "10px",
                }}
              >
                <img src={lock} width="16px" height="21px" alt="lock icon" />
              </span>
              <span className="d-flex justify-content-center align-items-center">
                <input
                  type="password"
                  placeholder="Pin"
                  name="pin"
                  value={loginData.pin}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  id="pin"
                  size="18"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    padding: "5px",
                    outline: "none",
                    width: "100%",
                  }}
                />
              </span>
            </div>
            {error && (
              <div className="flex justify-center items-center my-[12px]">
                <div
                  className="flex flex-row justify-center items-center"
                  style={{
                    gap: "4px",
                    color: "#D22727",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16.5px",
                  }}
                >
                  {error}
                </div>
              </div>
            )}
            <div className="d-flex flex-row justify-content-center">
              <Button
                onClick={handleLogin}
                disabled={loading}
                isLoading={loading}
                name="Enter"
              />
            </div>
          </div>
        </div>

        <div className="p-3 mb-5">
          <PoweredBy />
        </div>
      </div>

      <div
        className="hidden sm:flex flex-col items-end justify-center relative"
        style={{
          width: "45vw",
          height: "100vh",
        }}
      >
        <img
          src={background}
          alt="gradient view"
          className="absolute inset-0 w-full h-full object-cover"
        />

        <div className="w-[85%] h-full flex items-center">
          <div className="w-full relative flex justify-end">
            <div
              className="absolute z-10 w-[85%] aspect-[1.4/0.7] max-w-[412px]
        left-1/2 -translate-x-1/2 top-[-30px]"
              style={{
                borderRadius: "46px 46px 0px 0px",
                background: "rgba(255, 255, 255, 0.21)",
                border: "1px solid rgba(255, 255, 255, 0.52)",
                backdropFilter: "blur(4px)",
              }}
            >
              <div className="absolute left-[10%] top-[10%] w-[100%] text-white text-[16px] xl:text-[28px] font-bold leading-[26px] md:leading-[30px] xl:leading-[40px]">
                Fast, quick, <br /> and easy.
              </div>
            </div>

            <img
              src={ipad}
              alt="ipad_img"
              className="w-full max-w-[95%] lg:max-w-[100%] h-auto object-contain relative z-50"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
