import React from "react";

function AddOnChips({ label, price, isSelected, onSelect }) {
  const capitalizeFirstLetter = (letter) => {
    return letter.charAt(0).toUpperCase() + letter.slice(1);
  }
  return (
    <div
      className={`flex justify-between py-3 px-4 rounded-[80px] cursor-pointer ${
        isSelected
          ? "bg-[#E06F2C] text-white"
          : "text-[#E06F2C] "
      }`}
      style={{border: "1px solid #E06F2C"}}
      onClick={onSelect}
    >
      <div>{capitalizeFirstLetter(label)}</div>
      <div>{price === 0 ? "Free" : `+${price}`}</div>
    </div>
  );
}

export default AddOnChips;
