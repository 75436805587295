import React, { useState } from "react";
import cross from "../assets/cross.svg";
import AddOnChips from "./common/addOnChips";
import CommonButton from "./common/commonButton";

function AddOnModalContent({ onClose, item }) {
  const [selectedChips, setSelectedChips] = useState([]);



  const toggleChipSelection = (index) => {
    setSelectedChips((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((chipIndex) => chipIndex !== index) 
        : [...prevSelected, index] 
    );
  };

  const handleAddingAddOns = () => {
    onClose(true, selectedChips);
    setSelectedChips([]);
  };

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="flex w-full gap-3">
        <div className="w-24 h-28 bg-[#f8f8f8] rounded-lg">
          <img
            src={item.productImgUrl}
            alt="Add-on"
            className="object-contain w-full h-full"
          />
        </div>
        <div className="flex-grow-1 flex flex-col justify-between pb-2">
          <div className="font-[500] text-lg">{item.productName}</div>
          <div className="font-bold text-[22px]">${item.price.toFixed(2)}</div>
        </div>
        <div onClick={() => onClose(false)}>
          <img src={cross} alt="x" className="w-8 h-8" />
        </div>
      </div>

      <div className="w-full grid grid-cols-2 gap-3">
        {item?.addOns.map((addOn, index) => (
          <AddOnChips
            key={index}
            label={addOn.name}
            price={addOn.price}
            isSelected={selectedChips.includes(index)}
            onSelect={() => toggleChipSelection(index)}
          />
        ))}
      </div>

      <div className="flex justify-center gap-4">
        <CommonButton name="Add" onClick={handleAddingAddOns}/>
        <CommonButton name="Skip" background={"#9F9F9F"} onClick={onClose}/>
      </div>
    </div>
  );
}

export default AddOnModalContent;
