import React from "react";
import { CgSpinner } from "react-icons/cg";

const Button = (props) => {
  return (
    <button
      className="flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-white disabled:w-[160px] w-[124px]"
      onClick={props.onClick}
      style={{
        height: "52px",
        borderRadius: "16px",
        background:
          "linear-gradient(99.78deg, #E06F2C -5.85%, #FF9F66 109.55%)",
        boxShadow: "0px 8px 21px 0px #00000029",
        border: "0",
        fontWeight: 700,
        size: "12px",
        color: "#FFFFFF",
      }}
      disabled={props.disabled}
    >
      {props.isLoading && <CgSpinner size="20" className="animate-spin" />}
      {props.isLoading ? "Please wait..." : `${props.name}`}
    </button>
  );
};

export default Button;
