import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import Cross from "../assets/features/CrossModal.svg";
import { AuthContext } from "../auth/authProvider";
import toast from "react-hot-toast";
import axios from "axios";

function AddBalance(props) {
  const { user, token } = useContext(AuthContext);
  const [transaction, setTransaction] = useState({
    amount: 0,
    method: "",
  });

  const handleAddBalance = async () => {
    try {
      toast.loading(`Adding Balance to account ${props.selectedAccount.name} `);

      const payload = {
        userId: user.id,
        accountId: props.selectedAccount.accountId,
        transaction: {
          amount: parseFloat(transaction.amount),
          method: transaction.method,
        },
      };

      const res = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/account/addBalance`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res && res.data.success) {
        toast.dismiss();
        toast.success(res.data.message);
        props.handleBalanceUpdate(res.data.data.amount);
        props.handleClose();
      } else {
        toast.dismiss();
        toast.error("Failed to add balance");
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  };

  const isAddDisabled = () => {
    return transaction.amount <= 0 || transaction.method.trim() === "";
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="camper-balance-custom-modal"
      >
        <div className="d-flex flex-col w-[420px] h-[270px]">
          <div className="d-flex justify-between items-center h-[32px] mt-[20px]">
            <div
              style={{
                fontWeight: "700",
                fontSize: "21px",
                lineHeight: "31.5px",
                marginLeft: "33px",
              }}
            >
              Add Balances
            </div>
            <button
              className="w-[15.5px] h-[15.5px] me-[23px]"
              onClick={props.handleClose}
            >
              <img src={Cross} alt="cross" />
            </button>
          </div>
          <div
            className="h-[20px]"
            style={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "19.5px",
              marginLeft: "33px",
              color: "rgba(0, 0, 0, 0.28)",
            }}
          >
            {props.selectedAccount?.name}
          </div>
          <div className="d-flex flex-col justify-center items-center w-100 gap-[12px] mt-[20px]">
            <div
              className="d-flex items-center justify-center w-[250px] h-[40px] "
              style={{ background: "#f8f8f8", borderRadius: "9px" }}
            >
              <div className="ms-[15px]">$</div>
              <input
                placeholder="Amount"
                style={{
                  width: "100%",
                  border: "none",
                  outline: "none",
                  backgroundColor: "#f8f8f8",
                  paddingLeft: "10px",
                }}
                type="number"
                value={transaction.amount}
                onChange={(e) =>
                  setTransaction({
                    ...transaction,
                    amount: e.target.value,
                  })
                }
              />
            </div>
            <div className="floating-input-group h-[40px] w-[250px]">
              <input
                type="text"
                className="floating-input"
                value={transaction.method}
                onChange={(e) =>
                  setTransaction({
                    ...transaction,
                    method: e.target.value,
                  })
                }
              />
              <label
                className={`floating-placeholder ${
                  transaction.method.length === 0 ? "" : "floating-fill"
                }`}
              >
                Method
              </label>
            </div>
          </div>

          <div className="d-flex justify-center items-center h-[30px] mt-[15px]">
            <button
              className="h-[30px] w-[94px]"
              style={{
                backgroundColor: "#FF8943",
                borderRadius: "5px",
                color: "#fff",
                cursor: isAddDisabled() ? "not-allowed" : "pointer",
                opacity: isAddDisabled() ? 0.5 : 1,
              }}
              onClick={() => handleAddBalance()}
              disabled={isAddDisabled()}
            >
              Add
            </button>
          </div>
        </div>
      </Modal>
      <style jsx>{`
        .camper-balance-custom-modal .modal {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .camper-balance-custom-modal .modal-dialog {
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 420px;
          height: 300px;
        }

        .camper-balance-custom-modal .modal-content {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px !important;
          height: 280px;
        }

        .custom-placeholder::placeholder {
          color: red;
          font-size: 14px;
          font-weight: 400;
          line-height: 22.5px;
          color: rgba(0, 0, 0, 0.28);
        }
      `}</style>
    </>
  );
}

export default AddBalance;
