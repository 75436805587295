import React, { useState, useContext } from "react";
import { CiSearch } from "react-icons/ci";
import { Input } from "@chakra-ui/react";
import AddBalance from "../../addBalanceModal";
import accCross from "../../../assets/features/accCross.svg";
import { TiPlus } from "react-icons/ti";
import { AuthContext } from "../../../auth/authProvider";
import { useDispatchCart } from "../../contextReducer";
import { formatBalance } from "../../../utils";

const AccountType = ({
  accountType,
  setaccountType,
  accounts,
  setOrderedData,
  orderedData,
  selectedAccount,
  setSelectedAccount,
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const { user } = useContext(AuthContext);
  const dispatch = useDispatchCart();

  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => {
    setShowAdd(true);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length > 0) {
      const filteredSuggestions = accounts.filter(
        (account) =>
          account.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(value.toLowerCase().replace(/\s+/g, "")) ||
          account.autoGenerateNo.toString().includes(value)
      );
      setSuggestions(
        filteredSuggestions.filter((account) => !account.isDisabled)
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSelectedAccount(suggestion);
    setQuery("");
    setOrderedData({ ...orderedData, accountId: suggestion.accountId });
    setaccountType(suggestion.accountType);
    setSuggestions([]);
  };

  const handleBalanceUpdate = (updatedBalance) => {
    setSelectedAccount((prevAccount) => ({
      ...prevAccount,
      balance: parseFloat(selectedAccount.balance) + updatedBalance,
    }));
  };

  const handleCrossClick = () => {
    setaccountType("Default");
    setSelectedAccount(null);
    setOrderedData({ ...orderedData, accountId: "" });
    dispatch({ type: "CLEAR_CART" });
  };

  return (
    <>
      <div>
        {accountType === "Default" && (
          <div className="sm:h-[18vh] max-h-[150px] shadow-[8px_16px_58px_rgba(0,_0,_0,_0.07)] rounded-t-none rounded-b-xl bg-chocolate-100 max-w-full flex flex-col justify-center items-center relative">
            <div style={{ minWidth: "85%" }}>
              <div className="text-base font-medium font-poppins text-white text-left inline-block ms-[26px]">
                Find account
              </div>

              <div
                className="d-flex align-items-center h-[48px] mt-[10px]"
                style={{
                  borderRadius: "12px",
                  backgroundColor: "#EFEFEF",
                }}
              >
                <div className="h-[16px] ms-3">
                  <CiSearch width="16px" height="16px" />
                </div>
                <div
                  className="h-[48px] d-flex align-items-center mx-2"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="Search account by name or number"
                    variant="unstyled"
                    value={query}
                    onChange={handleChange}
                    onFocus={(e) => e.target.select()}
                  />
                </div>
              </div>
              {suggestions.length > 0 && (
                <div
                  className="absolute mt-[-9px] max-h-[50vh] overflow-y-auto custom-scrollbar"
                  style={{
                    minWidth: "85%",
                    boxShadow: "0px 4px 10px 0px #00000040",
                    backgroundColor: "#EFEFEF",
                    opacity: 1,
                    borderRadius: "0px 0px 12px 12px",
                  }}
                >
                  {suggestions.map((suggestion, index) => (
                    <div>
                      <div
                        key={index}
                        className="d-flex justify-between h-[52px] items-center hover:bg-gray-500 transition duration-300"
                        onClick={() => handleSuggestionClick(suggestion)}
                        style={{ color: "#525252", cursor: "pointer" }}
                      >
                        <div className="ms-[15px]">{suggestion.name}</div>
                        <div className="me-[15px]">
                          {suggestion.autoGenerateNo}
                        </div>
                      </div>
                      <div
                        className="w-[94%] ms-[8px]"
                        style={{ border: "0.2px solid #D6D6D6" }}
                      ></div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {accountType === "Camper" && (
          <div>
            <div className="relative sm:h-[18vh] max-h-[150px] d-flex justify-center items-center shadow-[8px_16px_58px_rgba(0,_0,_0,_0.09)] rounded-t-none rounded-b-xl bg-white max-w-full">
              <div className="d-flex w-[90%] h-[50%] justify-center items-center">
                <button
                  className="d-flex justify-center items-start w-[42px] h-[48px]"
                  onClick={handleCrossClick}
                >
                  <img src={accCross} alt="cross" />
                </button>
                <div className="d-flex flex-col justify-start flex-grow-1 ml-[20px]">
                  <div className="flex items-start font-semibold text-base leading-6 text-gray-600 ">
                    Bunk {selectedAccount.bunk}: ID{selectedAccount.autoGenerateNo}
                  </div>
                  <div className="flex justify-start align-end font-poppins font-bold text-xl leading-9 opacity-100">
                    {selectedAccount.name}
                  </div>
                </div>
                <div className="d-flex flex-col justify-end">
                  <div className="flex items-start font-semibold text-base leading-6 text-gray-600 opacity-62">
                    Balance:
                  </div>
                  <div className="flex align-bottom font-bold text-xl leading-9 text-gray-900 opacity-100 justify-end">
                    &#36;{selectedAccount.balance}
                  </div>
                </div>
              </div>
              {user.isAddBalanceEnabled && (
                <button
                  className="absolute bottom-[-40px] right-[36px] w-[40px] h-[40px] rounded-b-[15px] rounded-bl-[15px] bg-gradient-to-b from-orange-500 to-orange-300 border-2 border-white flex justify-center items-center"
                  onClick={() => handleShowAdd()}
                >
                  <TiPlus color="white" height={"13px"} width={"13px"} />
                </button>
              )}
            </div>
          </div>
        )}

        {accountType === "Staff" && (
          <div className="sm:h-[18vh] max-h-[150px] d-flex justify-center items-center shadow-[8px_16px_58px_rgba(0,_0,_0,_0.09)] rounded-t-none rounded-b-xl bg-white max-w-full">
            <div className="d-flex w-[90%] h-[50%] items-center">
              <button
                className="d-flex justify-center items-start w-[42px] h-[48px]"
                onClick={handleCrossClick}
              >
                <img src={accCross} alt="cross" />
              </button>
              <div className="d-flex flex-col justify-start flex-grow-1 ml-[20px]">
                <div className="flex items-start font-semibold text-base leading-6 text-gray-600 opacity-62">
                  Staff: {selectedAccount.autoGenerateNo}
                </div>
                <div className="flex justify-start items-end font-poppins font-bold text-xl leading-9 text-gray-900 opacity-100">
                  {selectedAccount.name}
                </div>
              </div>
              <div className="d-flex flex-col justify-end">
                <div className="flex items-start font-semibold text-base leading-6 text-gray-600 opacity-62">
                  Balance:
                </div>
                <div className="flex align-bottom font-poppins font-bold text-xl leading-9 text-gray-900 opacity-100 justify-end">
                  {formatBalance(selectedAccount.balance) || "$0.0"}
                  {/* &#36;{selectedAccount.balance || 0.0} */}
                </div>
              </div>
            </div>
          </div>
        )}

        {accountType === "Cost" && (
          <div className="sm:h-[18vh] max-h-[150px] d-flex justify-center items-center shadow-[8px_16px_58px_rgba(0,_0,_0,_0.09)] rounded-t-none rounded-b-xl bg-white max-w-full">
            <div className="d-flex w-[90%] h-[50%] items-center">
              <button
                className="d-flex justify-center items-start w-[42px] h-[48px]"
                onClick={handleCrossClick}
              >
                <img src={accCross} alt="cross" />
              </button>
              <div className="d-flex flex-col justify-start flex-grow-1 ml-[20px]">
                <div className="flex items-start font-semibold text-base leading-6 text-gray-600 opacity-62">
                  Cost: {selectedAccount.autoGenerateNo}
                </div>
                <div className="flex justify-start items-end font-poppins font-bold text-xl leading-9 text-gray-900 opacity-100">
                  {selectedAccount.name}
                </div>
              </div>
              <div className="d-flex flex-col justify-end">
                <div className="flex items-start font-semibold text-base leading-6 text-gray-600 opacity-62">
                  Balance:
                </div>
                <div className="flex align-bottom font-poppins font-bold text-xl leading-9 text-gray-900 opacity-100 justify-end">
                  {formatBalance(selectedAccount.balance) || "$0.0"}
                  {/* &#36;{selectedAccount.balance || 0.0} */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <AddBalance
        show={showAdd}
        setShow={setShowAdd}
        handleClose={handleCloseAdd}
        selectedAccount={selectedAccount}
        handleBalanceUpdate={handleBalanceUpdate}
      />
    </>
  );
};

export default AccountType;
