import React, { useEffect } from "react";

const Modal = ({ isVisible, onClose, children }) => {
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);

  if (!isVisible) return null;

  const handleClose = (e) => {
    if (e.target.id === "wrapper") {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex justify-center items-center p-4"
      id="wrapper"
      onClick={handleClose}
    >
      <div className="w-[33%] max-w-[1030px] max-h-[90vh] md:max-h-[95vh] flex items-center">
        <div className="w-full max-w-[1030px] h-full mx-auto flex flex-col">
          <div className="p-4 sm:p-5 relative h-full overflow-auto custom-scrollbar shadow-sm bg-white rounded-lg">
            {/* <button
              className="absolute top-2 right-2 sm:top-4 sm:right-4 text-xl font-bold p-2"
              style={{
                color: "#BDBDBD",
              }}
              onClick={onClose}
            >
              X
            </button> */}
            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
