import React, { useState } from "react";
import { BsCartPlus } from "react-icons/bs";
import { useCart } from "../contextReducer";

const Header = (props) => {
  const data = useCart();
  return (
    <div className="relative w-full h-16 flex items-center text-left text-5xl text-gray-100 header-title">
      <div
        className={`relative w-16 h-16 flex justify-center items-center text-xs ${
          props.showDropdown ? "text-chocolate-400" : "text-chocolate-200"
        }`}
      >
        <button
          className="relative w-[58px] h-[58px]"
          onClick={(e) => {
            props.setShowDropdown(true);
          }}
        >
          <div className="absolute top-0 left-0 rounded-lg bg-linen w-full h-full" />
          <BsCartPlus
            className="absolute top-[13px] left-[11px] w-[33px] h-[33px] overflow-hidden"
            alt="Cart Icon"
          />
        </button>
        <div className="absolute top-[-1px] left-[-3px] w-[22px] h-[22px]">
          <div className="d-flex justify-center items-center rounded-full bg-gainsboro w-full h-full">
            {data.length}
          </div>
        </div>
      </div>
      <div className="ml-4 flex-1">
        <div className="text-2xl md:text-base lg:text-2xl font-bold">
          Welcome to {props.user?.title}
        </div>
        <div className="text-sm md:text-base font-semibold font-nunito text-lightgray-100">
          {props.user?.subTitle}
        </div>
      </div>
    </div>
  );
};

export default Header;
