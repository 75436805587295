import React, { useState, useEffect } from "react";

const CandyProduct = ({ imageUrl, name, onClick, isSelected }) => {
  return (
    <div
      className={`relative inline-block w-[109px] h-[132px] ${
        isSelected ? "text-sienna" : ""
      }`}
      style={{ marginRight: "22px" }}
      onClick={onClick}
    >
      <div
        className={`rounded-lg border-[1px] border-solid w-full h-full cursor-pointer ${
          isSelected
            ? "bg-chocolate-300 border-[#E66E27]"
            : "bg-white border-lightgray-100"
        }`}
      >
        <img
          className="w-[51px] h-[51px] mx-auto mt-4"
          alt={name}
          src={imageUrl}
        />
        <div
          className={`text-center font-nunito mt-3 ${
            isSelected ? "text-sienna" : "text-gray-500"
          }`}
          style={{
            color: isSelected ? "#704332" : "",
            fontWeight: isSelected ? 600 : "",
          }}
        >
          {name}
        </div>
      </div>
    </div>
  );
};

const CategoryProduct = ({ categories, setSelectedCategoryId }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    if (categories.length > 0) {
      setSelectedCategory(categories[0].categoryId);
      setSelectedCategoryId(categories[0].categoryId);
    }
  }, [categories, setSelectedCategoryId]);

  const handleCategorySelect = (index) => {
    setSelectedCategory((prevSelected) =>
      prevSelected === index ? null : index
    );
    setSelectedCategoryId((prevSelected) =>
      prevSelected === index ? "" : index
    );
  };

  const sortedCategories = [...categories].sort((a, b) => a.pos - b.pos) || [];

  return (
    <div className="flex">
      {sortedCategories.map((category, index) => (
        <CandyProduct
          key={index}
          imageUrl={category.categoryImgUrl}
          name={category.categoryName}
          onClick={() => handleCategorySelect(category.categoryId)}
          isSelected={selectedCategory === category.categoryId}
        />
      ))}
    </div>
  );
};

export default CategoryProduct;
