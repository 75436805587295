import React, {useState } from "react";
import Layout from "../components/layout/layout.js";
import Dropdown from "../components/Dropdown.js";

const HomePage = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div>
      <Layout setShowDropdown={setShowDropdown} showDropdown={showDropdown}/>
      {
        showDropdown ? <Dropdown onClose={()=>{setShowDropdown(false)}}/> : ""
      }
    </div>
  );
};

export default HomePage;
