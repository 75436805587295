import React,{useRef ,useEffect, useState} from "react";
import upload from "../assets/features/upload.svg";
import connect from "../assets/features/connect.svg";
import logout from "../assets/features/logout.svg";
import { useCart } from "./contextReducer";
import LogoutModal from "./logoutModal";
import UploadOfflineModal from "./uploadOfflineModal";
function Dropdown(props) {
  const dropdownRef = useRef();
  const data= useCart();

  const [show, setShow] = useState(false);
  const [showOff, setShowOff] = useState(false);
  const handleCloseOff = () => setShowOff(false)
  const handleClose = () => setShow(false);

  const handleClick = (e)=>{
    if (dropdownRef && !dropdownRef.current.contains(e.target)) {
        if (!show && props.onClose)
         {
            props.onClose();
        }
      }
}
useEffect(()=>{
    document.addEventListener('click', handleClick,  { capture: true })

    return ()=>{
        document.removeEventListener('click', handleClick, { capture: true })
    }
})
  return (
    <>
    <div
      ref= {dropdownRef}
      className="d-flex flex-column absolute top-[80px] left-[100px] w-[310px] bg-white"
      style={{ borderRadius: "18px", boxShadow: "3px 3px 9px 2px #CACACA" }}
    >
      {/* <button
        className="d-flex w-100 h-[54px] items-center hover:bg-gray-500 "
        style={{ borderRadius: "18px 18px 0px 0px" }}
        onClick={() => setShowOff(true)}
      >
        <div className="d-flex relative w-100 h-[54px]">
          <div className="relative w-25">
            <img
              className="absolute top-[20px] left-[31px]"
              src={upload}
              height="24px"
              width="24px"
            />
            <div className="d-flex justify-center items-center absolute top-[12px] left-[17px] rounded-[50%] bg-gainsboro w-[22px] h-[22px]">
              <div className="absolute font-medium">{data.length}</div>
            </div>
          </div>

          <div className="w-100">
            <div className="absolute left-[71px] top-[19px] w-[189px] font-medium font-poppins text-left text-gray">
              Upload Offline Orders
            </div>
          </div>
        </div>
      </button> */}
      {/* <div className="w-[310.4px] box-border h-[0.4px] border-t-[0.4px] border-solid border-lightgray" />
      <button className="d-flex w-100 h-[54px] items-center hover:bg-gray-500">
        <div className="d-flex relative w-100 h-[54px]">
          <div className="relative w-25">
            <img
              className="absolute top-[18px] left-[29px]"
              src={connect}
              height="28px"
              width="28px"
            />
          </div>

          <div className="w-100">
            <div className="absolute left-[71px] top-[19px] w-[208px] font-medium font-poppins text-left text-gray">
              Connect Receipt Printer
            </div>
          </div>
        </div>
      </button> */}
      <div className="w-[310.4px] box-border h-[0.4px] border-t-[0.4px] border-solid border-lightgray" />
      <button
        className="d-flex w-100 h-[54px] items-center hover:bg-gray-500"
        style={{ borderRadius: "18px 18px 18px 18px" }}
        onClick={() => setShow(true)}
      >
        <div className="d-flex relative w-100 h-[54px]">
          <div className="relative w-25">
            <img
              className="absolute top-[16px] left-[31px]"
              src={logout}
              height="24px"
              width="24px"
            />
          </div>

          <div className="w-100">
            <div className="absolute left-[71px] top-[16px] w-[208px] font-medium font-poppins text-left text-gray">
              Logout
            </div>
          </div>
        </div>
      </button>
    </div>
    <LogoutModal show={show} setShow={setShow} handleClose={handleClose} />
    <UploadOfflineModal show={showOff} setShow={setShowOff} handleClose = {handleCloseOff} />
    </>
  );
}

export default Dropdown;
